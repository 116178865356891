export const Links = {
    "Portfolio":{
        name_ele: "Portfolio - Web",
        url: "https://www.rvcordero.com"
    },
    "Repository":{
        name_ele: "Repository - GitHub",
        url: "https://github.com/vanecordero"
    },
    "CSS":{
        name_ele: "CSS Paint",
        url: "https://codepen.io/vancor"
    }
}