import './App.css';
import Background from './pages/background';

function App() {
  return (
    <Background />   
  )
}

export default App;
